import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom'; // Importa us


export default function CheckoutForm({ selectedNumbers }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  console.log(selectedNumbers);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    // Aquí quitamos `return_url`
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Aquí puedes omitir el `return_url` para manejar la redirección manualmente
      },
      redirect: "if_required", // Esto previene redirección externa si no es necesario
    });

    if (error) {
      setMessage(error.message);
    } else {
      // Redirigimos manualmente a la página de éxito y pasamos `selectedNumbers`
      navigate('/success', {
        state: { 
          selectedNumbers 
        }
      });
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button className="btn-second w-button full-width btn-metodo" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? 'Procesando...' : 'Comprar Rifa'}
        </span>
      </button>
      {/* Show any error or success messages 
      {message && <div id="payment-message">{message}</div>}
      */}
    </form>
  );
}