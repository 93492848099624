import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import axios from 'axios';
import Payment from '../components/Stripe/Payment';
import { Link } from 'react-router-dom';


const DetailsPayRaffle = () => {
    const location = useLocation();
    const { raffleDetail, selectedNumbers } = location.state || [];
    const formattedNumbers = Array.isArray(selectedNumbers)
        ? selectedNumbers.flat().filter(Boolean).join(' - ')
        : 'Ningún número seleccionado';


    const [isChecked, setIsChecked] = useState(false);
    const apiURL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [price_per_ticket, setPrice_per_ticket] = useState(0); // Nuevo estado para el subtotal
    const [subtotal, setSubtotal] = useState(0); // Nuevo estado para el subtotal
    const [iva, setIva] = useState(0); // Nuevo estado para el IVA
    const [total, setTotal] = useState(0); // Nuevo estado para el total

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    useEffect(() => {
        const fetchDetailsRaffles = async () => {
            const token = localStorage.getItem('token');
            setLoading(true);
            try {
                const response = await axios.post(
                    `${apiURL}raffles/payment-detail/`,
                    {
                        raffle_id: raffleDetail.id,
                        numbers: selectedNumbers,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                // Asignar los valores recibidos de la API al estado
                console.log(response.data);
                const { price_per_ticket, subtotal, iva, total } = response.data; // Asegúrate de que tu API devuelva estos campos
                setPrice_per_ticket(price_per_ticket);
                setSubtotal(subtotal);
                setIva(iva);
                setTotal(total);

            } catch (error) {
                setError('Error al cargar los detalles de pago.');

                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        fetchDetailsRaffles(); // Llamar a la función cuando se monta el componente

    }, [apiURL, raffleDetail.id, selectedNumbers]); // Ejecutar el efecto cuando cambian estos valores




    return (
        <div>
            <Navbarlogin />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex">
                            <div className="container-details-pay w-form">
                                <h4>{raffleDetail.title || 'Nombre de la Rifa'}</h4>




                                <div className="font-graydark">
                                    Estas comprando los siguientes números para la rifa de una Tucson 2023 cuyo sorteo será el 25/06/2024 y tiene un valor de 10$ por ticket.
                                </div>
                                <div className="font-32 weight-600">{selectedNumbers.join(', ')}</div>

                                {/* Detalle de total a pagar */}
                                <div className="w-layout-vflex gap-10">
                                    <div className="weight-600">Total a pagar</div>
                                    <div className="w-layout-vflex gap-50">
                                        <div className="grid-details">
                                            <div className="font-graydark">
                                                Números
                                            </div>
                                            <div className="font-graydark">
                                                Valor por número
                                            </div>
                                            <div className="font-graydark">
                                                Total
                                            </div>
                                            <div className="font-graydark">
                                                {selectedNumbers.length}
                                            </div>
                                            <div className="font-graydark">
                                                {price_per_ticket} $
                                            </div>
                                            <div className="weight-800">
                                                {subtotal} $
                                            </div>
                                        </div>
                                        <div className="grid-details total">
                                            <div className="font-graydark">
                                                Subtotal
                                            </div>
                                            <div className="font-graydark text-right">
                                                {subtotal} $
                                            </div>
                                            <div className="font-graydark">
                                                IVA
                                            </div>
                                            <div className="font-graydark text-right">
                                                {iva} $
                                            </div>
                                            <div className="font-graydark">
                                                Total
                                            </div>
                                            <div className="font-graydark text-right">
                                                {total} $
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Deshabilitar el botón de pago si se ha alcanzado el límite */}
                                <div className="w-layout-vflex gap-10">
                                    <div className="weight-600">Métodos de pago</div>
                                    <div className="full-width">
                                        <div className="container-metodo-pago">
                                            <Payment
                                                raffle={raffleDetail.id}
                                                selected_numbers={selectedNumbers}
                                                isChecked={isChecked}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Botón de comprar rifa */}
                                <div className="w-layout-vflex duv-btn-buy-numbers">
                                    {/* <button
                                        className={`btn-second w-button ${loading || !isChecked ? 'disable' : ''}`} // Agrega la clase 'disabled' si no está activo
                                        onClick={handlePurchase}
                                        disabled={loading || !isChecked} // Se desactiva si está cargando o si el checkbox no está marcado
                                    >
                                        {loading ? 'Procesando...' : 'Comprar Rifa'}
                                    </button>
                                    {error && <div className="error">{error}</div>}
                                    {success && <div className="success">¡Compra exitosa!</div>} */}

                                    <label className="container-checkbox-terms"> Aceptar
                                        <Link to={`/terms-and-conditions/${raffleDetail.id}`} className="checkbox-label link">
                                            Ver terminos y condiciones de esta rifa
                                        </Link>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange} // Actualiza el estado cuando se hace click en el checkbox
                                        ></input>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <img src="/foto-rifa-caliente.png" loading="lazy" alt="" className="img-w-border" />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default DetailsPayRaffle;
