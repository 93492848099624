import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Payment({ raffle, selected_numbers, isChecked }) {
  const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  console.log(token);
  console.log(selected_numbers);

  const clientSecret =  localStorage.getItem('clientSecret');



  useEffect(() => {
    // Hacer la solicitud GET a tu servidor DRF para obtener la clave pública de Stripe
    fetch(`${apiURL}config/`)
      .then(async (r) => {
        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey));
        localStorage.setItem('publishableKey', publishableKey);
        
      });
  }, []);


  /* useEffect(() => {
    // Hacer la solicitud POST para crear el Payment Intent
    fetch(`${apiURL}raffles/purchase/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({
        raffle: raffle,  // Pasar el id de la rifa
        selected_numbers: selected_numbers,  // Pasar los números seleccionados
        terms_accepted: true
      }), 
    }).then(async (result) => {
      const { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []); */
  
  return (
    <>
      {clientSecret && stripePromise && isChecked && (
        <Elements stripe={stripePromise} options={{clientSecret}}>
          <CheckoutForm selectedNumbers={selected_numbers}/>
        </Elements>
      )}
      {!isChecked && (
        <div className="message">Debes aceptar los términos y condiciones para habilitar el botón de pago.</div>
      )}
    </>
  );
}
export default Payment;
