import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';

function HotRafflesModelTwo() {
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ is_hot: true }), []);
    const { raffles, loading, error } = useRaffles(queryParams);

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="full-width">
            <div className="grid-rifas-calientes">

                {raffles.map((raffle) => (
                    <div key={raffle.id} className="w-layout-vflex div-punto-fuerte pointer" onClick={() => handleRaffleClick(raffle.id)} >
                    <div className="div-img-rifa">
                        <img
                            src="/carro-hyundai.png"
                            loading="lazy"
                            alt=""
                        />
                    </div>
                    <div className="font-20 weight-600">{raffle.title}</div>
                    <p>{raffle.description}</p>
                    <div className="btn-link w-inline-block">
                        <div>Ver más</div>
                        <img src="/arrow-right.svg" loading="lazy" alt="" />
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default HotRafflesModelTwo;