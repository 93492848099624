import React, { useState } from 'react';

const NotificationsModal = ({ closeModal }) => {

  return (
      <div className="modal bg-modal bg-transparent">
        <div className="modal-content modal-noticaciones">
          <div className="w-layout-vflex gap-32 full-width notification">
            <h4 className="font-yellow">Notificaciones</h4>
            <div onClick={closeModal} className="close-btn notification" >
              <img
                loading="lazy"
                src="/btn-close-modal-ami.svg"
                alt="Cerrar"
              />
            </div>
          </div>
          <div className="line-gray-horizontal"></div>
          <div className="w-layout-vflex gap-24 full-width">
            <div className="w-layout-vflex container-notification">
              <div className="w-layout-hflex gap-20 align-center">
                <img
                  src="/Avatar.png"
                  loading="lazy"
                  alt="Avatar"
                  className="img-notification"
                />
                <div className="font-small font-weight-500">
                  Nueva venta de Riffapper
                </div>
                <div className="dot red"></div>
              </div>
              <div className="font-graydark">
                Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem
                ipsum dolor sit amet, consectetur adipiscing elit
              </div>
            </div>
            <div className="w-layout-vflex container-notification">
              <div className="w-layout-hflex gap-20 align-center">
                <img
                  src="/Avatar.png"
                  loading="lazy"
                  alt="Avatar"
                  className="img-notification"
                />
                <div className="font-small font-weight-500">
                  Nueva venta de Riffapper
                </div>
                <div className="dot red"></div>
              </div>
              <div className="font-graydark">
                Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem
                ipsum dolor sit amet, consectetur adipiscing elit
              </div>
            </div>
          </div>
        </div>
      </div>
    )
};

export default NotificationsModal;
