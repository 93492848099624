import React from 'react';
import Selector from './Checkboxes';

const Tab = ({ tabIndex, selectedNumbers, setSelectedNumbers, isNumberUnavailable }) => {

  const handleSelectorChange = (event, number) => {
    console.log(number);
    console.log('Evento:', event.target.checked);
    setSelectedNumbers((prev) => {
      const newNumbers = [...prev];
      if (event.target.checked) {
        console.log('Número seleccionado:', number);
        // Agrega el número solo si no está ya en la lista
        if (!newNumbers.includes(number)) {
          console.log('Número agregado:', number);
          newNumbers.push(number);
        }
      } else {
        console.log('Número deseleccionado:', number);
        // Elimina el número de la lista
        return newNumbers.filter(num => num !== number);
      }
      console.log('Array completo de números seleccionados:', newNumbers);
      return newNumbers; // Retorna la lista actualizada
    });
  };



  // Calcular el rango de números para cada tab
  const startNumber = tabIndex * 100;

  return (
    <div className="grid-numbers">
      {Array.from({ length: 100 }, (_, i) => {
        const number = startNumber + i;
        return (
          <Selector
            key={i}
            id={`tab${tabIndex}-selector${i}`}
            number={number}
            value={number}
            //value={selectedNumbers?.includes(number) ? number : ""}
            handleChange={(event) => handleSelectorChange(event,number)} // Cambié aquí para pasar el número
            disabled={isNumberUnavailable(number)} // Deshabilitar si es no disponible
          />
        );
      })}
    </div>
  );
};

export default Tab;


