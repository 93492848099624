import React, { useMemo } from 'react';
import useRaffles from '../../hooks/useRaffles';
import { useNavigate } from 'react-router-dom';

function ListRaffles() {
  const queryParams = useMemo(() => ({ status: 0 }), []);
  const navigate = useNavigate();
  const { raffles, loading, error } = useRaffles(queryParams);

  const handleRaffleClick = (id) => {
    navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="w-layout-hflex grid-rifas">
      {raffles.map((raffle) => (
        <div key={raffle.id} className="w-layout-vflex div-punto-fuerte">
          <div className="div-img-rifa">
            <img
              src="/carro-hyundai.png"
              loading="lazy"
              srcSet="/carro-hyundai.png"
              alt=""
            />
          </div>
          <div className="font-20 weight-600">{raffle.title}</div>
          <p>{raffle.description}</p>
          <div className="btn-link w-inline-block pointer" onClick={() => handleRaffleClick(raffle.id)}>
            <div>Ver más</div>
            <img src="/arrow-right.svg" loading="lazy" alt="" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListRaffles;