import React, { useState } from 'react';
import classNames from 'classnames';


const Collapsible = ({ id, title, content, isOpen, toggleCollapsible }) => {
  return (


    <div className="expandable-single" onClick={() => toggleCollapsible(id)}>
      <div className="expandable-top">
        <h4 className="font-18 weight-500">
          {title}
        </h4>
        <div className={classNames('arrow-expand-master', { 'open': isOpen, 'close': !isOpen })}>
          <img loading="lazy" src="/Icon.svg" alt="" />
        </div>
      </div>
      <div className={classNames('expandable-bottom', { 'open': isOpen, 'close': !isOpen })}>
        <div className="rich-text-block faq w-richtext">
          <p>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};



const FAQSection = () => {

  const [openCollapsibles, setOpenCollapsibles] = useState({});

  const toggleCollapsible = (id) => {
    setOpenCollapsibles((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const collapsibles = [
    { id: 1, title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { id: 2, title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { id: 3, title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { id: 4, title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit' },
  ];

  return (
    <section>
      <div className="w-layout-blockcontainer container w-container">
        <div className="w-layout-vflex container-month-winners">
          <div className="w-layout-vflex div-text-rifas-calientes">
            <h4>Preguntas frecuentes</h4>
            <p className="font-medium text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
          </div>
          <div className="w-layout-vflex container-faqs">
            {collapsibles.map((collapsible) => (
              <Collapsible
                key={collapsible.id}
                id={collapsible.id}
                title={collapsible.title}
                content={collapsible.content}
                isOpen={openCollapsibles[collapsible.id] || false}
                toggleCollapsible={toggleCollapsible}
              />
            ))}


          </div>
        </div>
      </div>

    </section>
  );
};

export default FAQSection;