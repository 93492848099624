import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';
import axios from 'axios';

function FinishedRaffles() {
    const apiURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ status: 4 }), []);
    const { raffles, loading, error } = useRaffles(queryParams);
    const [raffleStatuses, setRaffleStatuses] = useState({});

    // Función para obtener el estado de cada rifa
    const fetchRaffleStatus = async (id) => {
        const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};
        try {
            const response = await axios.get(`${apiURL}raffles/raffle-status/${id}`, { headers });
          
            console.log(`Raffle ${id} status:`, response.data); // Verifica lo que devuelve el API
            setRaffleStatuses((prevStatuses) => ({
                ...prevStatuses,
                [id]: response.data.is_winner
            }));
        } catch (err) {
            console.error('Error fetching status:', err);
        }
    };

    // Llama a fetchRaffleStatus cuando las rifas se carguen
    useEffect(() => {
        if (raffles.length > 0) {
            raffles.forEach((raffle) => {
                fetchRaffleStatus(raffle.id);
            });
        }
    }, [raffles]);

    const handleRaffleClick = (id) => {
        navigate(`/status-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="grid-rifas-calientes">
            {raffles.map((raffle) => (
                <div 
                    key={raffle.id} 
                    className="w-layout-vflex div-rifa-caliente"
                    onClick={() => handleRaffleClick(raffle.id)} // Navegar a la página de detalles
                >
                    <div className="price-rifa-caliente">${raffle.ticket_price}</div>
                    <div className="w-layout-vflex gap-24 full-width">
                        <div className="w-layout-vflex">
                            <div className="font-20 font-light weight-500">{raffle.title}</div>
                            <div className="font-small font-gray">{raffle.description}</div>
                        </div>
                        <div className={`message-rifa ${raffleStatuses[raffle.id] === true ? 'winner' : ''}`} >
                            {raffleStatuses[raffle.id] === true
                                ? 'Ganada'
                                : raffleStatuses[raffle.id] === false
                                ? 'Perdida'
                                : 'Cargando...'}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FinishedRaffles;
