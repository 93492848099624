import React, { useState, useContext } from 'react';
import UserContext from '../context/UserContext'; // Importa el contexto
import { useGoogleLogin } from "@react-oauth/google";
import GoogleButton from "react-google-button";
import { useNavigate } from 'react-router-dom'


function LoginGoogle() {
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState(null);
    const { updateUserInfo } = useContext(UserContext);

    

    const handleError = () => {
        console.log('Login Failed');
    };

    const handleSuccess = (codeResponse) => {
        const authorizationCode = codeResponse.code;
        console.log(codeResponse);
        console.log(authorizationCode);
        // setEmail(credentialResponseDecoded.email);

        fetch(`${apiURL}dj-rest-auth/login/google/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ code: authorizationCode }),
        })
            .then((response) => response.json())
            .then((data) => {

                const accessToken = data["access_token"];
                const username = data["name"]; // Asegúrate de que tu API devuelve el nombre
                
                // Almacenar el access_token en local storage
                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('username', username); // Almacena el nombre del usuario
                updateUserInfo({
                    access_token: accessToken,
                    username: username 
                  });
                navigate('/home-log-in')
            })
            .catch((error) => {
                // Handle errors in communicating with your backend server
                console.error("Error exchanging authorization code:", error);
            });

    };


    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        flow: "auth-code"
    })

    return (
        <div>
            {email === null &&
                <GoogleButton
                    onClick={login}
                    label="Iniciar sesión con google"
                />
            }
            {email && <p>El usuario ha iniciado sesión: {email}</p>}
        </div>
    );
}





export default LoginGoogle;
