import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ResetRequest = ({ onNext }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError('Por favor ingrese una dirección de correo electrónico válida.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${apiURL}dj-rest-auth/password/reset/`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSuccess(response.data.detail);
        onNext(email);  // Ahora pasa el email al componente padre
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.detail || 'Error al enviar el correo electrónico.');
      } else {
        setError('Error de red. Inténtalo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-layout-vflex div-log-in text-center">
      <h1 className="font-30">¿Has olvidado tu contraseña?</h1>
      <p>¡No te preocupes! Ingrese la dirección de correo electrónico vinculada con su cuenta.</p>
      <div className="form-block-log w-form">
        <form className="container-log-fields" onSubmit={handleSubmit}>
          <div className="w-layout-vflex">
            <input
              className="fields w-input"
              placeholder="Introduce tu email"
              type="email"
              id="Email-log-in"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <input
            type="submit"
            data-wait="Please wait..."
            className="btn-primary w-button"
            value={loading ? 'Enviando...' : 'Enviar código'}
            disabled={loading}
          />
        </form>
        {error && <div className="w-form-fail"><div>{error}</div></div>}
        {success && <div className="w-form-success"><div>{success}</div></div>}
      </div>
      <div className="font-small">
        ¿Recuerdas la contraseña? <Link to="/login">Ingresar</Link>
      </div>
    </div>
  );
};

export default ResetRequest;
