import React, { useState } from 'react';
import Tab from './Tabs';

const SelectorRaffle = ({ selectedNumbers,unavailableNumbers,setSelectedNumbers }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleNumbersChange = (tabIndex, number) => {
    console.log('Número seleccionado handle:', number);
    setSelectedNumbers((prevSelectedNumbers) => {
      const newSelectedNumbers = [...prevSelectedNumbers];
      
      // Si el número ya está seleccionado, lo eliminamos; de lo contrario, lo agregamos
      if (newSelectedNumbers[tabIndex].includes(number)) {
        newSelectedNumbers[tabIndex] = newSelectedNumbers[tabIndex].filter(num => num !== number);
      } else {
        newSelectedNumbers[tabIndex].push(number); // Agregar el número seleccionado
      }
      
      console.log('Array completo de números seleccionados:', newSelectedNumbers);
      console.log(`Números seleccionados en Tab ${tabIndex + 1}: ${newSelectedNumbers[tabIndex].join(', ')}`);
      return newSelectedNumbers; // Asegúrate de devolver el nuevo estado
    });
  };

  const isNumberUnavailable = (number) => {
    return unavailableNumbers?.length ? unavailableNumbers.includes(number) : false;
  };

  return (
    <div>
      <div className="menu-opc-numbers w-tab-menu">
        {Array.from({ length: 10 }, (_, i) => {
          const startNumber = i * 100;
          const endNumber = startNumber + 99;
          return (
            <a
              className={`tab-opc-numbers w-inline-block w-tab-link ${activeTab === i ? 'w--current' : ''}`}
              key={i}
              onClick={() => handleTabClick(i)}
            >
              {startNumber}-{endNumber}
            </a>
          );
        })}
      </div>

      <div>
        {Array.from({ length: 10 }, (_, i) => (
          <div key={i} style={{ display: activeTab === i ? "block" : "none" }}>
            <Tab
              tabIndex={i}
              selectedNumbers={selectedNumbers[i]}
              setSelectedNumbers={setSelectedNumbers}
              //setSelectedNumbers={(number) => handleNumbersChange(i, number)} // Aquí está bien
              isNumberUnavailable={isNumberUnavailable}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectorRaffle;
