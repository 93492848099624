import React,  { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import NumbersList from '../components/Raffles/NumbersSelected';



const BuySuccess = () => {
    const location = useLocation();
    const { selectedNumbers } = location.state || [];
    console.log(selectedNumbers);
    const formattedNumbers = Array.isArray(selectedNumbers)
        ? selectedNumbers.flat().filter(Boolean).join(' - ')
        : 'Ningún número seleccionado';

    return (
        <div>
            <Navbarlogin />
            <section className='bg-yellow'>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">

                    <div className="w-layout-vflex align-center container-result">
                        <img src="/check-icon.svg" loading="lazy" alt="" />

                        <div className='w-layout-vflex align-center gap-16'>
                            <div>
                                Felicidades ya estas participando en la Riffa seleccionada con lo(s) numero(s)
                            </div>
                            <div className="font-light number-bought">{formattedNumbers}</div>
                        </div>

                        {/* <div className='w-layout-hflex align-center gap-16'>
                            <a><img src="/icon-like.svg" loading="lazy" alt="" /></a>
                            <a><img src="/icon-download.svg" loading="lazy" alt="" /></a>
                            <a><img src="/icon-sent.svg" loading="lazy" alt="" /></a>
                        </div> */}

                        <Link to="/my-raffles" className='btn-light full-width w-button'>
                            Finalizar
                        </Link>



                    </div>

                </div>
            </section>
            <Footer />

        </div>


    );
};

export default BuySuccess;
