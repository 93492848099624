import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


const ResultRaffle = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [winningNumber, setWinningNumber] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiURL = process.env.REACT_APP_API_URL;

    const handleComeBack = (id) => {
        navigate(`/status-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    useEffect(() => {
        const fetchRaffleDetail = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};

            try {
                const winningNumberResponse = await axios.get(`${apiURL}raffles/winning_numbers/${id}/`, { headers });
                const winningNumberData = winningNumberResponse.data.results[0]?.number;
                setWinningNumber(winningNumberData);

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRaffleDetail();
    }, [id]);
    return (
        <div>
            <Navbarlogin />
            <section className='bg-yellow'>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">

                    <div className="w-layout-vflex align-center container-result">
                        <h4>Resultado</h4>
                        <div className='w-layout-vflex align-center gap-16'>

                            <div>
                                El resultado final de la rifa es
                            </div>
                            <div className="w-layout-vflex align-center div-code-password full-width justify-center">
                                <div className="div-container-number-result">{winningNumber ? winningNumber : 'Cargando número ganador...'}</div>
                               
                            </div>
                        </div>

                        <img src="/check-icon.svg" loading="lazy" alt="" />
                        <div className='w-layout-vflex align-center'>
                            <div className="font-small">Todos los resultados esta sujetos a la Ley 892479 y son verificables bajo el sorteo 8429 de la Loteria registrada del LotoFeliz de Caracas Venezuela 702,</div>
                            <a className='link-light ' href="#">Verificar</a>
                        </div>

                        <div onClick={() => handleComeBack(id)} className='btn-light full-width w-button'>
                            Volver
                        </div>



                    </div>

                </div>
            </section>
            <Footer />

        </div>


    );
};

export default ResultRaffle;
