import React from 'react';


const Footer = () => {
    return (
      <section>
        <div className="w-layout-blockcontainer container footer w-container">
          <div className="w-layout-hflex container-footer">
            <div className="w-layout-hflex container-opc-footer">
              <div className="w-layout-vflex gap-16">
                <div className="letter-strong">Company</div>
                <a href="#">About us</a>
                <a href="#">Careers</a>
                <a href="#">Press</a>
                <a href="#">News</a>
                <a href="#">Media kit</a>
                <a href="#">Contact</a>
              </div>
              <div className="w-layout-vflex gap-16">
                <div className="letter-strong">Resources</div>
                <a href="#">Blog</a>
                <a href="#">Newsletter</a>
                <a href="#">Events</a>
                <a href="#">Help centre</a>
                <a href="#">Tutorials</a>
                <a href="#">Support</a>
              </div>
              <div className="w-layout-vflex gap-16">
                <div className="letter-strong">Social</div>
                <a href="#">Twitter</a>
                <a href="#">LinkedIn</a>
                <a href="#">Facebook</a>
                <a href="#">Tutorials</a>
              </div>
              <div className="w-layout-vflex gap-16">
                <div className="letter-strong">Legal</div>
                <a href="#">Terms</a>
                <a href="#">Privacy</a>
                <a href="#">Cookies</a>
                <a href="#">Licenses</a>
                <a href="#">Settings</a>
                <a href="#">Contact</a>
              </div>
            </div>
            <div className="w-layout-vflex gap-16">
              <div className="letter-strong">Company</div>
              <img src="/Mobile-app-store-badge.svg" alt="Mobile app store badge" />
              <img src="/Mobile-app-store-badge-1.svg" alt="Mobile app store badge 1" />
            </div>
          </div>
          <div className="w-layout-hflex container-footer after">
            <img src="/logo-riffapp.png" alt="Riffapp logo" className="logo-navbar" />
            <div className="copyright">© 2024 Riffapp. All rights reserved.</div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Footer;