import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import DatePicker from "react-datepicker";
import 'rsuite/DatePicker/styles/index.css';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import "react-datepicker/dist/react-datepicker.css";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { DatePicker } from 'rsuite';





const SignupSection = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [user, setuser] = useState('');
    const [codigo, setCodigo] = useState('+58412');
    const [phone, setPhone] = useState('');
    const [ci, setCi] = useState('');
    const [tipoci, setTipoci] = useState('V');
    const [password, setPassword] = useState('');
    const [errorpassword, setErrorpassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [modalVisible, setModalVisible] = useState(false); // Estado para controlar la visibilidad del modal
    const apiURL = process.env.REACT_APP_API_URL;

    const telconcatenado = `${codigo}${phone}`;
    console.log(telconcatenado);

    const ciconcatenado = `${tipoci}${ci}`;
    console.log(ciconcatenado);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handlePasswordBlur = () => {
        // Validar que la contraseña tenga al menos 8 caracteres
        if (password.length < 8) {
            setErrorpassword('La contraseña debe tener al menos 8 caracteres');
        } else {
            setErrorpassword('');
        }
    };

    const handleConfirmPasswordBlur = () => {
        // Verificar si las contraseñas coinciden
        if (confirmPassword !== password) {
            setConfirmError('No coincide con la contraseña principal');
        } else {
            setConfirmError('');
        }
    };

    const traducirError = (mensaje) => {
        switch (mensaje) {
            case 'This password is too common.':
                return 'Esta contraseña es demasiado común.';
            case 'Enter a valid phone number.':
                return 'Introduce un número de teléfono válido.';
            case 'Ensure this field has at least 6 characters.':
                return 'Asegurece que la cédula tenga al menos 6 caracteres.';
            case 'National ID format is invalid.':
                return 'Formato de documento invalido.';
            default:
                return 'Error desconocido.';
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(
                `${apiURL}dj-rest-auth/registration/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cookie': 'django_language=es-ve',  // Añadir el cookie header si es necesario
                    },
                    body: JSON.stringify({
                        email: email,
                        name: user,
                        phone_number: telconcatenado,
                        national_id: ciconcatenado,
                        password1: password,
                        password2: confirmPassword
                    })
                });

            console.log(response);

            if (response.ok) {
                setModalVisible(true); // Mostrar el modal
            } else {
                const errorData = await response.json();
                const errores = [
                    ...(errorData.detail?.password1 || []),
                    ...(errorData.detail?.phone_number || []),
                    ...(errorData.detail?.national_id || [])
                ];
                setError(errores.map(traducirError));
            }
        } catch (err) {

            console.error('Error al intentar autenticar:', err);
            setError('Error de red. Inténtalo de nuevo más tarde.');
        }
    };



    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-hflex container-log">
                    <div className="w-layout-vflex container-form-log">
                        <div className="w-layout-vflex div-log-in">
                            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar" />
                            <h1 className="font-30">Registrarte</h1>
                            <div className="form-block-log w-form">
                                <form className="container-log-fields" onSubmit={handleSubmit}>

                                    {/* Campo de telefono */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Email-sing-up" className="font-small font-graydark">Teléfono*</label>
                                        <div className="w-layout-hflex full-width">
                                            <select className="fields select-field left-field w-select" value={codigo} onChange={(e) => setCodigo(e.target.value)}>
                                                <option value="+58412">0412</option>
                                                <option value="+58414">0414</option>
                                                <option value="+58424">0424</option>
                                                <option value="+58416">0416</option>
                                                <option value="+58426">0426</option>
                                            </select>
                                            <input className="fields w-input right-field" placeholder="Ingresa tu número telefónico" type="number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                        </div>
                                    </div>

                                    {/* Campo de email */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Email-sing-up" className="font-small font-graydark">Email*</label>
                                        <input className="fields w-input" name="Email" placeholder="Ingresa tu email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>

                                    {/* Campo usuario */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Name-sing-up" className="font-small font-graydark">Usuario*</label>
                                        <input className="fields w-input" placeholder="Ingresa un nombre de usuario" type="text" value={user} onChange={(e) => setuser(e.target.value)} />
                                        <div style={{ display: 'none' }} className="error-message">Usuario no disponible</div>
                                    </div>

                                    {/* Campo contrasena */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Password-sing-up" className="font-small font-graydark">Contraseña*</label>
                                        <input className="fields w-input"
                                            placeholder="Introduce una contraseña"
                                            type="password"
                                            onChange={handlePasswordChange}  // Actualiza el valor del input
                                            onBlur={handlePasswordBlur}  // Valida cuando el input pierde el foco 
                                        />
                                        {errorpassword && <div className="error-message">{errorpassword}</div>}
                                        <div className="font-small format-password">Debe tener al menos 8 caracteres.</div>
                                    </div>

                                    {/* Campo verificacion contrasena */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Confirm-password-sing-up" className="font-small font-graydark">Confirmar contraseña*</label>
                                        <input className="fields w-input"
                                            placeholder="Introduzca nuevamente la contraseña"
                                            type="password"
                                            onChange={handleConfirmPasswordChange}  // Actualiza el valor del input
                                            onBlur={handleConfirmPasswordBlur}  // Valida cuando el input pierde el foco
                                        />
                                        {confirmError && <div className="error-message">{confirmError}</div>}
                                    </div>

                                    {/* Campo Documento */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Email-sing-up" className="font-small font-graydark">Documento*</label>
                                        <div className="w-layout-hflex full-width">
                                            <select className="fields select-field left-field w-select" value={tipoci} onChange={(e) => setTipoci(e.target.value)}>
                                                <option value="v">V</option>
                                                <option value="e">E</option>
                                                <option value="j">J</option>
                                            </select>
                                            <input className="fields w-input right-field" placeholder="Ingresa tu cédula" type="number" value={ci} onChange={(e) => setCi(e.target.value)} />
                                        </div>
                                    </div>

                                    {/* Campo fecha de nacimiento */}
                                    <div className="w-layout-vflex gap-10">
                                        <label htmlFor="Name-sing-up" className="font-small font-graydark">Fecha de nacimiento*</label>
                                        <DatePicker placeholder="Seleccione una fecha"/>
                                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                    </div>
                                    {error.length > 0 && (
                                        <div className="w-layout-vflex gap-10 full-width">
                                            {error.map((error, index) => (
                                                <div key={index} className="error-message">{error}</div>
                                            ))}
                                        </div>
                                    )}
                                    <button type="submit" className="btn-primary w-button">Registrarme</button>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex container-full-yellow _w-border"></div>
                </div>
            </section>

            {/* Modal */}
            {modalVisible && (
                <div className="bg-modal"
                    style={{ visibility: modalVisible ? 'visible' : 'hidden', opacity: modalVisible ? 1 : 0, transition: 'opacity 0.5s ease', }}>
                    <div className="modal-signup">
                        <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar centrado" />
                        <div className="w-layout-vflex gap-32 full-width">
                            <div className="close-btn ami">
                                <Link to="/my-raffles">
                                    <img loading="lazy" src="/btn-close-modal-ami.svg" alt="Close Button" />
                                </Link>

                            </div>
                            <div className="w-layout-vflex gap-16">
                                <img loading="lazy" src="/icon-success.svg" alt="Success Icon" />
                                <div className="w-layout-vflex">
                                    <h4 className="font-18 weight-600">¡Usuario creado con éxito!</h4>
                                    <div>Ya puedes comprar tu primera rifa</div>
                                </div>
                            </div>
                            <Link to="/login" className="btn-primary full-width w-button">
                                Aceptar
                            </Link>

                        </div>
                    </div>
                </div>
            )}


            <Footer />



        </div>
    );
};

export default SignupSection;
