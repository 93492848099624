import React, { useEffect, useState } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const RaffleTermsandConditions = () => {
    const { id } = useParams(); // Obtener el ID de la rifa desde la URL
    const [raffleTerms, setRaffleTerms] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRaffleDetail = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/terms-and-conditions/${id}/`, { headers });
                setRaffleTerms(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRaffleDetail();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <Navbarlogin />

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Leer antes de comprar</div>
                            <h4>Términos y Condiciones de RiffApp</h4>
                        </div>
                        <div className="w-layout-vflex">
                            <p>
                                {raffleTerms.content} 
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
};

export default RaffleTermsandConditions;
