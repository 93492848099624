import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const InfoRaffle = () => {
    const { id } = useParams(); // Obtener el ID de la rifa desde la URL
    const [raffleDetail, setRaffleDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchRaffleDetail = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};
            
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/${id}/`, { headers });
                setRaffleDetail(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRaffleDetail();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <Navbarlogin />
            <section>
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex container-rifa-details">
                        <div className="font-yellow">Rifa</div>
                        <h3>{raffleDetail.title}</h3>
                        <div className="font-20 font-ray-dark">
                            {raffleDetail.description}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
                    <a href="#" className="banner-cta-rifa w-inline-block">
                        <div className="w-layout-vflex gap-24">
                            <div className="w-layout-vflex gap-8">
                                <h1 className="font-40 font-light">{raffleDetail.title}</h1>
                                <div className="font-light">No pierdas la oportunidad de ganar este SUV</div>
                            </div>
                            <div className="w-layout-hflex align-center gap-24">
                                <img src="/Group-37.svg" loading="lazy" alt="" />
                                <div className="font-12 font-gray">{raffleDetail.ticket_price}</div>
                                <img src="/Group-39.svg" loading="lazy" alt="" />
                                <div className="font-12 font-gray">{new Date(raffleDetail.start_date).toLocaleDateString()}</div>
                            </div>
                        </div>
                        <div className="w-layout-vflex gap-24">
                            <img src="Icon-arrow.svg" loading="lazy" alt="" className="element-right" />
                            <div className="w-layout-vflex gap-16">
                                <div className="font-small font-light weight-600">Categoría</div>
                                <div className="w-layout-hflex gap-8">
                                    <div className="category">Carros</div>
                                    <div className="category">Hyundai</div>
                                    <div className="category">Riffagrande</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex align-center">
                    <Link 
                            to={`/select-raffle/${raffleDetail.id}`} 
                            state={{ raffleDetail }} // Pasar detalles de la rifa a la siguiente página
                            className="btn-second big w-button"
                        >
                            Comprar Rifa
                        </Link>

                    </div>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
                    <div className="w-layout-hflex container-perfil-aliado">
                        <div className="w-layout-hflex align-center gap-24">
                            <img
                                src="/logo-hyundai.png"
                                loading="lazy"
                                sizes="96px"
                                srcSet="
                  /logo-hyundai-p-500.png 500w,
                  /logo-hyundai-p-800.png 800w,
                  /logo-hyundai-p-1080.png 1080w,
                  /logo-hyundai-p-1600.png 1600w,
                  /logo-hyundai-p-2000.png 2000w,
                  /logo-hyundai.png 2560w
                "
                                alt=""
                                className="img-perfil-aliado"
                            />
                            <div className="w-layout-vflex gap-8">
                                <div className="font-18 weight-600">Hyundai</div>
                                <div className="font-graydark">Aliado de carros de Riffapp</div>
                            </div>
                        </div>
                        <a href="#" className="w-inline-block">
                            <img src="/IC_Filter.svg" loading="lazy" alt="" />
                        </a>
                    </div>

                    <div className="grid-img-aliados">
                        <a href="#" className="w-inline-block w-lightbox">
                            <img
                                src="/foto-rifa-caliente.png"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 28vw, (max-width: 991px) 29vw, 30vw"
                                srcSet="
                  /foto-rifa-caliente-p-500.png 500w,
                  /foto-rifa-caliente-p-800.png 800w,
                  /foto-rifa-caliente.png 1024w
                "
                                alt=""
                                className="img-aliado"
                            />
                        </a>

                        <a href="#" className="w-inline-block w-lightbox">
                            <img
                                src="/carro2.jpeg"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 28vw, (max-width: 991px) 29vw, 30vw"
                                srcSet="
                  /carro2-p-500.jpeg 500w,
                  /carro2-p-800.jpeg 800w,
                  /carro2-p-1080.jpeg 1080w,
                  /carro2-p-1600.jpeg 1600w,
                  /carro2.jpeg 1940w
                "
                                alt=""
                                className="img-aliado"
                            />
                        </a>

                        <a href="#" className="w-inline-block w-lightbox">
                            <img
                                src="/carro3.jpeg"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 28vw, (max-width: 991px) 29vw, 30vw"
                                srcSet="
                  /carro3-p-500.jpeg 500w,
                  /carro3-p-800.jpeg 800w,
                  /carro3-p-1080.jpeg 1080w,
                  /carro3.jpeg 1200w
                "
                                alt=""
                                className="img-aliado"
                            />
                        </a>

                        <a href="#" className="w-inline-block w-lightbox">
                            <img
                                src="/foto-rifa-caliente.png"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 28vw, (max-width: 991px) 29vw, 30vw"
                                srcSet="
                  /foto-rifa-caliente-p-500.png 500w,
                  /foto-rifa-caliente-p-800.png 800w,
                  /foto-rifa-caliente.png 1024w
                "
                                alt=""
                                className="img-aliado"
                            />
                        </a>

                        <a href="#" className="w-inline-block w-lightbox">
                            <img
                                src="/carro2.jpeg"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 28vw, (max-width: 991px) 29vw, 30vw"
                                srcSet="
                  /carro2-p-500.jpeg 500w,
                  /carro2-p-800.jpeg 800w,
                  /carro2-p-1080.jpeg 1080w,
                  /carro2-p-1600.jpeg 1600w,
                  /carro2.jpeg 1940w
                "
                                alt=""
                                className="img-aliado"
                            />
                        </a>
                    </div>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex align-center">
                    <Link 
                            to={`/select-raffle/${raffleDetail.id}`} 
                            state={{ raffleDetail }} // Pasar detalles de la rifa a la siguiente página
                            className="btn-second big w-button"
                        >
                            Comprar Rifa
                        </Link>

                    </div>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Mayor oportunidad de ganar</div>
                            <h4>Otras rifas</h4>
                            <p className="font-medium text-center">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                            </p>
                        </div>
                        <div className="grid-rifas-calientes">
                            <div className="w-layout-vflex div-rifa-caliente">
                                <div className="price-rifa-caliente">$20,00</div>
                                <div className="w-layout-vflex">
                                    <div className="font-20 font-light weight-500">Hyundai Tucson 2023</div>
                                    <div className="font-small font-gray">Ganar este SUV</div>
                                </div>
                            </div>

                            <div className="w-layout-vflex div-rifa-caliente">
                                <div className="price-rifa-caliente">$20,00</div>
                                <div className="w-layout-vflex">
                                    <div className="font-20 font-light weight-500">Ford Mustang 2023</div>
                                    <div className="font-small font-gray">Este increíble auto podría ser tuyo</div>
                                </div>
                            </div>

                            <div className="w-layout-vflex div-rifa-caliente">
                                <div className="price-rifa-caliente">$15,00</div>
                                <div className="w-layout-vflex">
                                    <div className="font-20 font-light weight-500">Bicicleta Eléctrica X5</div>
                                    <div className="font-small font-gray">Tu próximo medio de transporte</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    );
};

export default InfoRaffle;
