import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'; 
import { Link as ScrollLink } from 'react-scroll';   





const Navbar = () => {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabHover = (tab) => {
    setActiveTab(tab);
  };

  const handleTabLeave = (tab) => {
    setActiveTab(null);
  };


  return (
    <div className="container-navbar w-nav">
      <div className="container navbar w-container">
        <RouterLink to="/" className="w-nav-brand">
          <img src="/logo-riffapp.png" loading="lazy" alt="Logo" className="logo-navbar" />
        </RouterLink>

        <nav role="navigation" className="nav-menu w-nav-menu">
          <div className="w-layout-hflex container-options-menu">
            <RouterLink to="/" className="nav-link w-nav-link">
              Inicio
            </RouterLink>
            <div className="w-layout-hflex container-options-menu">
              <ScrollLink to="raffles" smooth={true} duration={500} className="nav-link w-nav-link">
                Rifas
              </ScrollLink>
            </div>
        
            {/* <a href="#" className="nav-link w-nav-link">
              Riffapper
            </a> */}
          </div>
          <div className="w-layout-hflex container-options-menu">
            <RouterLink to="/login" className="nav-link w-nav-link">
              Ingresar
            </RouterLink>
            <RouterLink to="/signup" className="btn-primary w-button">
              Registrarte
            </RouterLink>

          </div>
        </nav>
        <div className="w-nav-button">
          <div className="w-icon-nav-menu"></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
