import React, { useState, useEffect } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import axios from 'axios';
import Footer from '../components/Footer';
import SelectorRaffle from '../components/SelectorNumberRaffle/TabsSelectorLoterry';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';



const SelectNumberRaffle = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const activeTab = 'Tab 1';
    const location = useLocation();
    const { raffleDetail } = location.state || {}; // Extraer detalles de la rifa desde el state
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;

    const [unavailableNumbers, setUnavailableNumbers] = useState([]);
    const [loadingUnavailable, setLoadingUnavailable] = useState(true);
    const [errorUnavailable, setErrorUnavailable] = useState(null);
    const [selectedNumbers, setSelectedNumbers] = useState(() => unavailableNumbers?.length ? unavailableNumbers : []);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [limitReached, setLimitReached] = useState(false);

    // Manejar cambios en el campo de búsqueda
    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        console.log(onSearch);
        //onSearch(term); // Llamar a la función de búsqueda pasada como prop
    };


    useEffect(() => {
        const fetchUnavailableNumbers = async () => {
            const token = localStorage.getItem('token');
            const headers = token ? { Authorization: `Token ${token}` } : {};
            setLoadingUnavailable(true);
            try {
                const response = await axios.get(`${apiURL}raffles/unavailable_numbers/${raffleDetail.id}/`, { headers });
                setUnavailableNumbers(response.data.unavailable_numbers);
            } catch (err) {
                setErrorUnavailable(err.message);
            } finally {
                setLoadingUnavailable(false);
            }
        };

        if (raffleDetail) {
            fetchUnavailableNumbers();
        }
    }, [raffleDetail]);

    
    /// Limpiar el estado de error si el usuario modifica los números seleccionados
    const handleNumbersUpdate = (newSelectedNumbers) => {
        setSelectedNumbers(newSelectedNumbers);

        // Si el número de seleccionados baja, habilitar el botón y ocultar el mensaje de error
        if (newSelectedNumbers.length > 0 && limitReached) {
            setLimitReached(false); // Oculta el mensaje de límite alcanzado
            setError(null); // Limpia cualquier mensaje de error
        }

        // Si se deseleccionan todos los números, habilitar el botón
        if (newSelectedNumbers.length === 0) {
            setLimitReached(false);
        }
    };


    // Función para generar el número de la suerte
    const generateLuckyNumber = () => {
        const availableNumbers = Array.from({ length: 999 }, (_, i) => i + 1) // Genera array de 1 a 999
            .filter(num => !unavailableNumbers.includes(num) && !selectedNumbers.includes(num)); // Filtra los no disponibles y los ya seleccionados

        if (availableNumbers.length > 0) {
            const randomIndex = Math.floor(Math.random() * availableNumbers.length);
            const luckyNumber = availableNumbers[randomIndex];
            setSelectedNumbers((prev) => [...prev, luckyNumber]); // Agrega el número generado a la lista de seleccionados
        } else {
            console.log('No hay números disponibles para seleccionar');
        }
    };

    // Manejar el envío del formulario (compra de rifa)
    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(null);

        try {
            
            const response = await axios.post(
                `${apiURL}raffles/purchase/`,
                {
                    raffle: raffleDetail.id,
                    selected_numbers: selectedNumbers,
                    terms_accepted: true,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.status === 200) {
                setSuccess(true);
                setLimitReached(false);
                navigate('/details-pay-raffle', { state: { raffleDetail, selectedNumbers } });
                const clientSecret = response.data.clientSecret;
                const paymentIntentId = response.data.paymentIntentId;
                console.log(clientSecret);
                localStorage.setItem('clientSecret', clientSecret);
                localStorage.setItem('paymentIntentId', paymentIntentId);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const { detail, code } = error.response.data;
                if (code === 'DATO_INVALIDO' && detail?.non_field_errors?.includes('Has alcanzado el límite de compras para esta rifa.')) {
                    setLimitReached(true);
                    setError('Has alcanzado el límite de compras para esta rifa.');
                } else {
                    setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
                }
            } else {
                setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
            }
        } finally {
            setLoading(false);
        }
    };



    return (
        <div>
            <Navbarlogin />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex gap-32">
                            <h4>{raffleDetail ? raffleDetail.title : 'Nombre de la Rifa'}</h4>
                            {/* Mostrar el error de límite de compra */}
                            {limitReached && (
                                <div className="error-message full-width error-big">
                                    Este perfil ha alcanzado el número máximo permitido de tickets para esta rifa. Ya ha seleccionado un total de <strong>10 números</strong>
                                </div>
                            )}
                            <button className="btn-second w-button full-width" onClick={generateLuckyNumber}>
                                Generar mi número de la suerte
                                <img src="/icon-star-white.svg" loading="lazy" alt="" className="icon-star" />
                            </button>
                            {/* <input
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="fields w-input"
                                placeholder="Introduzca texto a buscar"
                                type="text"
                            /> */}
                            <div className="w-form full-width">
                                <form
                                    className="container-tabs-numbers"
                                    onSubmit={handleSubmit}
                                >

                                    <SelectorRaffle
                                        selectedNumbers={selectedNumbers}
                                        unavailableNumbers={unavailableNumbers}
                                        setSelectedNumbers={handleNumbersUpdate} />



                                    <div className="w-layout-vflex duv-btn-buy-numbers">

                                        <button type="submit"
                                            className={`btn-second w-button ${selectedNumbers.length === 0 || limitReached ? 'disable' : ''}`}
                                            value="Comprar Rifa"
                                            disabled={selectedNumbers.length === 0 || limitReached} // Se desactiva si no hay números seleccionados
                                        >Comprar Rifa</button>
                                        <Link
                                            to={`/terms-and-conditions/${raffleDetail.id}`}
                                            className="link">
                                            Ver terminos y condiciones de esta rifa
                                        </Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                        <img
                            src="/foto-rifa-caliente.png"
                            loading="lazy"
                            alt=""
                            className="img-w-border"
                        />
                    </div>
                </div>
            </section>

            <Footer />

        </div>
    );
};

export default SelectNumberRaffle;

