import React from 'react';

const Selector = ({ id, number, value, handleChange, disabled }) => (
  <label className="w-checkbox container-checkbox">
    <input
      type="checkbox"
      id={id}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      className={`w-checkbox-input w-checkbox-input--inputType-custom check ${disabled ? 'btn-disable-raffle' : ''}`} // Añade la clase aquí
    />
    <span className={`check ${disabled ? 'btn-disable-raffle' : ''}`} htmlFor={id}>{number}</span>
  </label>
);

export default Selector;


