import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';



const BecomeRiffaper = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const [name, setname] = useState('');
    const [phone, setPhone] = useState('');
    const [ci, setCi] = useState('');
    const [bank, setbank] = useState('');
    const [error, setError] = useState('');
    const [modalVisible, setModalVisible] = useState(false); // Estado para controlar la visibilidad del modal


    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');
        console.log(token);

        try {
            const response = await fetch(`${apiURL}riffapper/convert-to-riffapper/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                   // 'Cookie': 'django_language=es-ve',
                    'Authorization': `Token ${token}`,  // Añadir el cookie header si es necesario
                },
                body: JSON.stringify({
                    name: name,
                    national_id: ci,
                    bank: 1,
                    phone_number: phone
                })
            });

            console.log(response);

            if (response.ok) {
                setModalVisible(true); // Mostrar el modal
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Error en la autenticación');
            }
        } catch (err) {

            console.error('Error al intentar autenticar:', err);
            setError('Error de red. Inténtalo de nuevo más tarde.');
        }
    };



    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-hflex container-log">
                    <div className="w-layout-vflex container-form-log">
                        <div className="w-layout-vflex div-log-in">
                            <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar" />
                            <h1 className="font-30">Alta de Riffapper</h1>
                            <div>
                                Pago movil
                            </div>
                            <div className="form-block-log w-form">
                                <form id="wf-form-Sing-up-Form" className="container-log-fields" onSubmit={handleSubmit}>
                                    <div className="w-layout-vflex gap-10">
                                        <label className="font-small font-graydark">Nombre*</label>
                                        <input className="fields w-input" placeholder="Ingresa un nombre de usuario" type="text" value={name} onChange={(e) => setname(e.target.value)} />
                              
                                    </div>

                                    <div className="w-layout-vflex gap-10">
                                        <label  className="font-small font-graydark">Documento*</label>
                                        <input className="fields w-input" placeholder="Ingresa tu cédula" type="text" value={ci} onChange={(e) => setCi(e.target.value)} />
                                    </div>

                                    <div className="w-layout-vflex gap-10">
                                        <label className="font-small font-graydark">Banco*</label>
                                        <input className="fields w-input" placeholder="Ingresa banco" type="text" value={bank} onChange={(e) => setbank(e.target.value)} />
                                
                                    </div>

                                    <div className="w-layout-vflex gap-10">
                                        <label className="font-small font-graydark">Teléfono*</label>
                                        <input className="fields w-input" placeholder="Ingresa tu número telefónico" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </div>


                                    <button type="submit" className="btn-primary w-button">Guardar</button>
                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-vflex container-full-yellow"></div>
                </div>
            </section>

            {/* Modal */}
            {modalVisible && (
                <div className="bg-modal"
                    style={{ visibility: modalVisible ? 'visible' : 'hidden', opacity: modalVisible ? 1 : 0, transition: 'opacity 0.5s ease', }}>
                    <div className="modal-signup">
                        <img src="/logo-riffapp.png" loading="lazy" alt="" className="logo-navbar centrado" />
                        <div className="w-layout-vflex gap-32 full-width">
                            <div className="close-btn ami">
                                <Link to="/my-raffles">
                                    <img loading="lazy" src="/btn-close-modal-ami.svg" alt="Close Button" />
                                </Link>

                            </div>
                            <div className="w-layout-vflex gap-16">
                                <img loading="lazy" src="/icon-success.svg" alt="Success Icon" />
                                <div className="w-layout-vflex">
                                    <h4 className="font-18 weight-600">¡Riffapper creado con éxito!</h4>
                                    <div>ya puedes promocionar rifas y ganar</div>
                                </div>
                            </div>
                            <Link to="/profile-riffapper" className="btn-primary full-width w-button">
                                Ir al perfil
                            </Link>

                        </div>
                    </div>
                </div>
            )}


            <Footer />



        </div>
    );
};

export default BecomeRiffaper;
