import React, { useState, useContext, useEffect } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopiadoContext } from './CopiadoContext';

const CopyToClipboardLink = (link) => {
    const { mostrarMensaje, actualizarLink  } = useContext(CopiadoContext);
    const [error, setError] = useState('');
    const manejarCopiado = () => {
        mostrarMensaje();
    };

    console.log(link)

    return (
        <div>
            <CopyToClipboard text={link.text} onCopy={manejarCopiado}>
                <button className="btn-light w-button" disabled={!link}>Copiar en portapapeles</button>
            </CopyToClipboard>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default CopyToClipboardLink;
