import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import Login from './pages/LoginPage';
import HomePage from './pages/HomePage';
import SignupSection from './pages/SignupPage';
import InfoRaffle from './pages/InfoRafflePage';
import SelectNumberRaffle from './pages/SelectNumberRafflePage';
import DetailsPayRaffle from './pages/DetailsPayRafflePage';
import MyProfile from './pages/ProfilePage';
import MyRaffles from './pages/MyRafflesPage';
import ResetPassword from './pages/ResetPasswordPage';
import ResultSearchPage from './pages/ResultSearchPage';
import StatusRaffle from './pages/StatusMyRafflePage';
import ResultRaffle from './pages/ResultRafflePage';
import UserProfile from './pages/UserProfilePage';
import BuySuccess from './pages/SuccessPage';
import BecomeRiffaper from './pages/BecomeRiffapperPage';
import RiffaperProfile from './pages/RiffapperProfilePage';
import { CopiadoProvider } from './components/Riffapper/CopiadoContext';
import RaffleTermsandConditions from './pages/TermsAndConditions';
import { UserProvider } from './context/UserContext'; 
import ProtectedRoute from './components/ProtectedRoute';
import ContactPage from './pages/Help';


function App() {
  const [email, setEmail] = useState('');
  const handleNext = (email) => {
    setEmail(email);
  };

  return (
    <CopiadoProvider>
      <Router>
        <UserProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignupSection />} />
            

            {/* Rutas protegidas */}
            <Route path="/info-raffle/:id" element={<ProtectedRoute><InfoRaffle /></ProtectedRoute>} />
            <Route path="/select-raffle/:id" element={<ProtectedRoute><SelectNumberRaffle /></ProtectedRoute>} />
            <Route path="/home-log-in" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
            <Route path="/my-raffles" element={<ProtectedRoute><MyRaffles /></ProtectedRoute>} />
            <Route path="/user-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
            <Route path="/reset-password" element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} />
            <Route path='/status-raffle/:id' element={<StatusRaffle />} />
            <Route path='/result-raffle/:id' element={<ProtectedRoute><ResultRaffle /></ProtectedRoute>} />
            <Route path='/success' element={<ProtectedRoute><BuySuccess /></ProtectedRoute>} />
            <Route path="/results" element={<ProtectedRoute><ResultSearchPage /></ProtectedRoute>}/>
            <Route path="/details-pay-raffle" element={<ProtectedRoute><DetailsPayRaffle /></ProtectedRoute>} />
            <Route path='/become-riffapper' element={<ProtectedRoute><BecomeRiffaper /></ProtectedRoute>} />
            <Route path='/profile-riffapper' element={<ProtectedRoute><RiffaperProfile /></ProtectedRoute>} />
            <Route path='/terms-and-conditions/:id' element={<ProtectedRoute><RaffleTermsandConditions /></ProtectedRoute>} />
            <Route path='/help' element={<ProtectedRoute><ContactPage /></ProtectedRoute>} />
          </Routes>
        </UserProvider>
      </Router>
    </CopiadoProvider>
  );
}

export default App;
