import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbarlogin from '../components/Navbarlogin';
import Footer from '../components/Footer';
import ActiveRaffles from '../components/Raffles/ActiveRaffles';

const UserProfile = () => {


  return (
    <div>
      <Navbarlogin />
      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-32 w-container">  
        <div className="w-layout-hflex align-center gap-24">
                            <img
                                src="/perfil.jpeg"
                                loading="lazy"
                                sizes="96px"
                                alt=""
                                className="img-perfil-nav userpage"
                            />
                            <div className="w-layout-vflex gap-8">
                                <div className="font-18 weight-600">Rosa Maria</div>
                                <div className="font-graydark">Silver</div>
                            </div>
                        </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
              <h4>Rifas activas</h4>
            <ActiveRaffles />

          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-rifas-calientes">
              <h4>Rifas activas</h4>
            <ActiveRaffles />
          </div>
        </div>
      </section>
      <Footer />


    </div>
  );
};

export default UserProfile;
